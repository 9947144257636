.tick line {
  stroke: #c0c0bb;
}
.tick text {
  fill: #635f5d;
}
.marks {
  path {
    stroke-width: 4;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
}
.axis-label {
  font-size: 1.2em;
  fill: #635f5d;
}
.legend {
  fill: #635f5d;
}
