html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body,
#root {
  height: 100%;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Hide default editor action buttons*/
.ct-ignition {
  display: none !important;
}

.toastBase {
  color: white !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  min-height: 60px !important;
  background-color: #212121 !important;
  font-size: 0.9rem !important;
  line-height: 1rem;
}

.toastMessageBase {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-bottom: 0.3rem;
}

.toastSuccess > div:first-of-type {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #00d600;
  display: flex;
  flex: 0 0 24px;
  justify-content: center;
  align-items: center;
}

.closeToastButton {
  color: white;
  align-self: center;
  padding: 4px;
}

.progressError {
  background: #e01e00 !important;
}

.progressSuccess {
  background: #00d600 !important;
}

.progressInfo {
  background: #0061e0 !important;
}

.zsiq_floatmain #titlediv {
  display: none;
}

// styling editor toolbox
.ct-app {
  .ct-widget {
    z-index: 1280;

    .ct-grip {
      display: none;
    }

    .ct-tool-group {
      padding: 0;
    }

    .ct-tool {
      color: #fff;

      &:hover:not(.ct-tool--disabled) {
        background: rgba(255, 255, 255, 0.33);
      }

      @media (max-width: 830px) {
        margin: 4px 0;
      }
    }

    .ct-tool--disabled {
      color: rgba(255, 255, 255, 0.33);
    }

    .ct-tool--applied {
      background: rgba(0, 0, 0, 0.5);
    }

    &.ct-toolbox {
      width: 100%;
      left: 0 !important;
      top: 89px !important;
      padding: 0 8px;
      background: #545871;
      border: 0;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

      .ct-tool-groups {
        display: flex;
        justify-content: center;
      }

      @media (max-width: 694px) {
        top: 131px !important;
      }
    }
  }

  .ct-image-dialog {
    &__file-upload {
      width: 100%;
      height: 100%;
    }

    .ct-control--upload,
    .ct-control--insert,
    .ct-control--cancel,
    .ct-control--clear {
      width: auto;
      min-width: 100px;
      padding: 0 1rem;
    }
  }
}

.tox .tox-dialog__header {
  border-bottom: 1px solid rgb(204, 204, 204) !important;
  font-weight: bold !important;
  padding: 12px 24px !important;
}

.tox .tox-dialog__body {
  flex: 1 1 auto;
  padding: 8px 24px !important;
}

.tox .tox-label,
.tox .tox-toolbar-label {
  display: block;
  color: rgb(0, 51, 78) !important;
  margin: 1rem 0 0.3rem !important;
  font-size: 1rem !important;
}

.tox .tox-dialog__body-content {
  padding: 0 16px 16px !important;
}

.ul-guidelines-style {
  list-style: circle;
  padding-left: 20px;
  margin-bottom: 20px;
}

/** CALENDAR */
.main-article .sc-calendar {
  width: 100%;
}

.main-article .sc-calendar tr,
.main-article .sc-calendar tbody {
  width: 100%;
}

caption {
  text-align: center;
  font-size: 130%;
  font-weight: bold;
  padding: 10px 0;
}

.main-article .sc-calendar a {
  color: #4b7bdb;
  text-decoration: none;
}

.main-article .sc-calendar td,
.main-article .sc-calendar th {
  padding: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.main-article .sc-calendar .weekdays {
  background: #4b7bdb;
}

.main-article .sc-calendar .weekdays th {
  text-align: center;
  line-height: 20px;
  border: none !important;
  padding: 10px 6px;
  color: #fff;
  font-size: 13px;
  width: calc(100% / 7);
}

.main-article .sc-calendar td {
  min-height: 120px;
  height: 120px;
  width: calc(100% / 7);
}

.main-article .sc-calendar .days li:hover {
  background: #d3d3d3;
}

.main-article .sc-calendar .date {
  text-align: center;
  margin-bottom: 3px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
  color: #000;
  width: 22px;
  height: 22px;
  font-size: 12px;
  border-radius: 50%;
  flex: 0 0 auto;
  align-self: flex-end;
}

.main-article .sc-calendar .no-event {
  flex: 0 0 auto;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 3px;
  line-height: 18px;
  // background: #e4f2f2;
  border: 1px solid transparent;
  color: #009aaf;
  text-decoration: none;
  height: 22px;
}

.main-article .sc-calendar .event {
  flex: 0 0 auto;
  font-size: 12px;
  // border-radius: 4px;
  padding: 2px 5px;
  margin-bottom: 3px;
  line-height: 18px;
  background: #567ad4;
  border-top: 1px solid #567ad4;
  border-bottom: 1px solid #567ad4;
  color: #fff;
  text-decoration: none;
  height: 22px;
  overflow: hidden;
  cursor: pointer;
}

.main-article .sc-calendar .event.start {
  border-left: 1px solid #567ad4;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: 5px;
}

.main-article .sc-calendar .event.end {
  border-right: 1px solid #567ad4;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-right: 5px;
}

.main-article .sc-calendar .event .icons {
  float: right;
}

.main-article .sc-calendar .event-desc {
  color: #666;
  margin: 3px 0 7px 0;
  text-decoration: none;
}

.main-article .sc-calendar .other-month .date {
  color: #666;
}

.main-article .sc-calendar .other-month {
  background: #f5f5f5;
  color: #666;
}

/* ============================
				Mobile Responsiveness
   ============================*/

@media (max-width: 768px) {
  .main-article .sc-calendar .weekdays,
  .main-article .sc-calendar .other-month {
    display: none;
  }

  .main-article .sc-calendar li {
    height: auto !important;
    border: 1px solid #ededed;
    width: 100%;
    padding: 10px;
    margin-bottom: -1px;
  }

  .main-article .sc-calendar td {
    height: auto;
    width: 100%;
    display: table;
    min-height: 50px;
  }

  .main-article .sc-calendar .date {
    align-self: flex-start;
  }
}
